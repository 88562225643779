// src/components/Papers.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PapersSection = styled.section`
  min-height: 100vh;
  padding: 100px 20px;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  color: #2980b9;
  font-weight: 600;
  margin-bottom: 40px;
  font-family: 'Poppins', sans-serif;
`;

const PaperBox = styled(motion.div)`
  background-color: #fff;
  padding: 30px;
  margin: 20px 0;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const PaperTitle = styled.a`
  font-size: 1.2rem;
  color: #2980b9;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 15px;
  transition: color 0.3s ease;

  &:hover {
    color: #1abc9c;
  }
`;

const NonProfitWork = styled.div`
  margin-top: 30px;
  padding: 20px;
  background-color: #eef5fa;
  border-left: 4px solid #2980b9;
  border-radius: 10px;
`;

const TextItem = styled.p`
  font-size: 1.1rem;
  color: #555;
`;

const Papers = () => {
  return (
    <PapersSection id="papers">
      <Title>Research, Papers, & Publications</Title>

      <PaperBox
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <PaperTitle href="/path/to/paper1.pdf" target="_blank">
          Beyond History: The Psychological Tapestry of the Holocaust
        </PaperTitle>
        <PaperTitle href="/path/to/paper2.pdf" target="_blank">
          The Pervasive Trauma of Genocides: A Deep Dive into the Holocaust and its Universal Impacts
        </PaperTitle>
        <PaperTitle href="/path/to/paper3.pdf" target="_blank">
          The Illusion of Glory: Literature's Confrontation with War Propaganda
        </PaperTitle>
        <PaperTitle href="/path/to/paper4.pdf" target="_blank">
          Unmasking the Illusion of War
        </PaperTitle>
        <PaperTitle href="/path/to/paper5.pdf" target="_blank">
          Autonomy and Gender: A Synthesis of Existential and Feminist Philosophies
        </PaperTitle>
        <PaperTitle href="/path/to/paper6.pdf" target="_blank">
          From Molds to Metamorphosis: The Evolution of Female Identity in Literature and Culture
        </PaperTitle>
        <PaperTitle href="/path/to/paper7.pdf" target="_blank">
          Personal Essay - 'What's Real, True, and Good'
        </PaperTitle>

        <NonProfitWork>
          <TextItem>
            <strong>Equity in Access:</strong> Compiling SEL-oriented resources and conducting research to spread equity of access for a non-profit. This work focuses on providing resources to underserved communities and improving mental health initiatives.
          </TextItem>
        </NonProfitWork>
      </PaperBox>
    </PapersSection>
  );
};

export default Papers;
